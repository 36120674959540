
import { Component, Vue } from "vue-property-decorator";
import { $auth, AuthRole } from "@/pages/auth/services/auth.service";
import { getDomainByBasicInformation } from "@/api/account"
@Component({
  name: "login-page"
})
export default class LoginPage extends Vue {
  urlData = '';
  codeImageUrl = '';
  roles = Object.values($auth.roles);
  currentRole: AuthRole = $auth.buildRole();
  form = $auth.form;
  loading = false;
  isMounted = false;
  mobileForm = $auth.mobileForm;
  activeName = 'account';
  smsSuffixText = '获取验证码';
  smsTimeout = 0;
  smsCount = 0;
  get pwdCover() {
    return new Array(this.form.pwd.length).fill('●').join('');
  }

  mounted() {
    if (location.host.indexOf('sshlqf') === -1) {
      const oldUrlList = location.host.split(".");
      const endUrl =
        oldUrlList[oldUrlList.length - 2] +
        "." +
        oldUrlList[oldUrlList.length - 1]; // 获取当前地址栏后缀主域名地址
      getDomainByBasicInformation(endUrl).then(res => {
        this.urlData = res.text;
        document.title = res.name;
        this.handleIcoCreate(res.logo)
      })
    } else {
      this.urlData = '山南水北（北京）生态科技发展有限公司 | 京ICP备2020039568号-1';
      document.title = '山水互联';
      const imgUrl = 'https://sshlwap.oss-cn-beijing.aliyuncs.com/files/normal/56690F1AB073464CB6604A0BCE8E2758.png'
      this.handleIcoCreate(imgUrl)
    }
    this.refreshCode();
    setTimeout(() => {
      this.isMounted = true;
    }, 500);
  }

  handleIcoCreate(icoUrl) {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    console.log(link);
    link.href = icoUrl;
  }

  async clickSmsSuffixText() {
    let is: any
    await (this.$refs.mobileFormRef as any).validateField('mobile', (valid: any) => void (is = valid))

    if (this.smsTimeout || is) return
    const { mobile, captcha } = this.mobileForm
    if (!/^1[3456789]\d{9}$/.test(mobile)) return
    await $auth.smscodeDropShipping(mobile, captcha || undefined)
    let timer: any = null
    this.smsTimeout = 60
    this.smsSuffixText = '60秒'
    timer = setInterval(() => {
      this.smsTimeout--;
      if (this.smsTimeout <= 0) {
        this.smsSuffixText = '获取验证码';
        this.smsTimeout = 0;
        clearInterval(timer);
      } else {
        this.smsSuffixText = this.smsTimeout + '秒';
      }
    }, 1000);
  }

  refreshCode() {
    this.codeImageUrl = this.currentRole.getCodeImageUrl('LOGIN');
    this.$forceUpdate();
  }

  switchRole(role) {
    this.currentRole = $auth.switchRole(role);
    $auth.clearForm();
    this.refreshCode();
  }

  removeAccount(ref, value: string) {
    this.currentRole.removeAccount(value);
    ref.debouncedGetData(ref.value);
  }

  handleSelectAccount(account) {
    $auth.selectAccount(account.value, account.pwd);
    this.$forceUpdate();
  }

  // login(formRef) {
  //   formRef.validate(valid => {
  //     if (valid) {
  //       this.loading = true;
  //       $auth.login().then(() => {
  //         this.loading = false;
  //       }, () => {
  //         this.loading = false;
  //         this.refreshCode();
  //       });
  //     }
  //   });
  // }
  async login(formRef) {
    try {
      const valid = await formRef.validate();
      console.log(valid);

      if (!valid) return;
      this.loading = true;
      try {
        if (this.activeName === 'account') await $auth.login();
        else if (this.activeName === 'mobile') {
          try {
            await $auth.loginDropShipping();
          } catch (error) {
            this.refreshCode();
            this.smsCount++
          }
        }
      } catch (error) {
        this.refreshCode();
      } finally {
        this.loading = false;
      }
    } catch (error) {

    }
  }
}
